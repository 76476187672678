<template>
  <div v-on:keyup.enter="login">
    <!-- Header -->
    <div class="header bg-accent1 py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <!-- <h1 class="text-white">Welcome!</h1> -->
              <!-- <p class="text-lead text-white">Use these awesome forms to login or create new account in your project for free.</p> -->
              <img class="login-logo" src="@/assets/images/apa-logo.png">
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-accent2" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <!-- <div class="card-header bg-transparent pb-5">
              <div class="text-muted text-center mt-2 mb-3"><small>Sign in with</small></div>
              <div class="btn-wrapper text-center">
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="img/icons/common/github.svg"></span>
                  <span class="btn-inner--text">Github</span>
                </a>
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="img/icons/common/google.svg"></span>
                  <span class="btn-inner--text">Google</span>
                </a>
              </div>
            </div> -->
            <div class="card-body px-lg-5 py-lg-5">
              <!-- <div class="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div> -->
              <form role="form" v-on:keyup.enter="login">
                <base-input alternative
                            class="mb-3"
                            prepend-icon="ni ni-email-83"
                            placeholder="E-mail"
                            v-model="credentials.email"
                            >
                </base-input>

                <base-input alternative
                            class="mb-3"
                            prepend-icon="ni ni-lock-circle-open"
                            type="password"
                            placeholder="Password"
                            v-model="credentials.password">
                </base-input>

                <base-checkbox v-if="showPrivacy" v-model="privacy"><span v-html="$t('message.privacy_message')"></span></base-checkbox><br>
                <base-checkbox v-if="showPrivacy" v-model="terms"><span v-html="$t('message.terms_message')"></span></base-checkbox>
                <div class="text-center">
                  <base-button type="primary" class="my-4" @click="login" :loading="isLoading">Login</base-button>
                </div>
                <div v-if="message" class="text-center error">
                  {{message}}
                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <!-- <router-link to="/my-dashboard" class="text-light"><small>Passwort vergessen?</small></router-link> -->
            </div>
            <div class="col-12 text-right">
              <router-link to="/forgot" class="text-light"><small>{{$t('message.password_lost')}}</small></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import EnvLoader from '@/plugins/env-loader'
  import envs from '@/env'
  import router from '@/routes/router'

  const NO_PRIVACY = 12345

  export default {
    data() {
      return {
        credentials: {
          email: '',
          password: ''
        },
        isLoading: false,
        message: null,
        showPrivacy: false,
        privacy: false,
        terms: false,
      };
    },
    methods: {
      login () {
        const self = this;
        this.isLoading = true;
        this.message = null;
        const redirection = '/my-dashboard';
        this.$auth.login(this.credentials)
          .then(this.askForPrivacy)
          .then(() => {

            // Login to Nextcloud - later done with user credentials
            //window.location = "https://apapics.codelight.de/nextcloud/index.php/login?user=admin&password=ptN8;zAqABdQ&redirect_url=" + window.location.href;

            const ncUrl = EnvLoader.env(envs).ncUrl + '/apps/pics?user=admin&password=ptN8;zAqABdQ';
            return fetch(ncUrl, {
              method: 'GET',
              credentials: 'include',
              mode: 'no-cors',
            }).then(() => {
              console.log("nc login success");

              router.push({
                path: redirection,
                function () {
                  self.isLoading = false;
                }
              })

            }).catch(e => {
              console.log("nc login error", e);

              router.push({
                path: redirection,
                function () {
                  self.isLoading = false;
                }
              })



            })
          })
          .catch(error => {
            if (error.status === 403) {
              this.message = self.$t('message.login_failed')
            }
            if (error.status === NO_PRIVACY) {
              this.message = self.$t('message.no_privacy')
            }
            self.isLoading = false
          })
      },
      askForPrivacy(user) {
        if (user.privacy) return user

        if (!this.privacy || !this.terms) {
          this.showPrivacy = true
          throw {status: NO_PRIVACY}
        }
        this.$store.dispatch('updateUser', { id: user.id, data: {privacy: true} });

        return user
      }
    }
  };
</script>
