<template>
  <div v-on:keyup.enter="login">
    <!-- Header -->
    <div class="header bg-accent1 py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <!-- <h1 class="text-white">Welcome!</h1> -->
              <!-- <p class="text-lead text-white">Use these awesome forms to login or create new account in your project for free.</p> -->
              <img class="login-logo" src="@/assets/images/apa-logo.png">
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-accent2" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <!-- <div class="card-header bg-transparent pb-5">
              <div class="text-muted text-center mt-2 mb-3"><small>Sign in with</small></div>
              <div class="btn-wrapper text-center">
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="img/icons/common/github.svg"></span>
                  <span class="btn-inner--text">Github</span>
                </a>
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="img/icons/common/google.svg"></span>
                  <span class="btn-inner--text">Google</span>
                </a>
              </div>
            </div> -->
            <div class="card-body px-lg-5 py-lg-5">
              <!-- <div class="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div> -->
              <form role="form" v-on:keyup.enter="change">
                <base-input alternative
                            class="mb-3"
                            prepend-icon="ni ni-lock-circle-open"
                            type="password"
                            placeholder="Password"
                            v-model="credentials.password">
                </base-input>
                <base-input alternative
                            class="mb-3"
                            prepend-icon="ni ni-lock-circle-open"
                            type="password"
                            placeholder="Bestätigen"
                            v-model="credentials.confirm">
                </base-input>

                <div class="text-center">
                  <base-button type="primary" class="my-4" @click="change" :loading="isLoading" :disabled="!credentials.password || credentials.password != credentials.confirm">{{$t('message.password_change')}}</base-button>
                </div>
                <div v-if="message" class="text-center error">
                  {{message}}
                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <!-- <router-link to="/my-dashboard" class="text-light"><small>Passwort vergessen?</small></router-link> -->
            </div>
            <div class="col-12 text-right">
              <router-link to="/login" class="text-light"><small>{{$t('message.login')}}</small></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import EnvLoader from '@/plugins/env-loader'
  import envs from '@/env'
  import router from '@/routes/router'

  const NO_PRIVACY = 12345

  export default {
    data() {
      return {
        credentials: {
          password: '',
          confirm: '',
        },
        isLoading: false,
        message: null,
        showPrivacy: false,
        privacy: false,
        terms: false,
      };
    },
    methods: {
      change () {
        const self = this;
        this.isLoading = true;
        this.message = null;
        const redirection = '/login';
        const base = EnvLoader.env(envs).rootUrl + '../..';
        this.$http.post(base+'/change', {
          password: this.credentials.password,
          token: this.$route.params.token,
        }).then(() => {

            router.push({
              path: redirection,
              function () {
                self.isLoading = false;
              }
            })
          })
          .catch(error => {
            if (error.status === 403) {
              this.message = self.$t('message.error')
            }
            self.isLoading = false
          })
      },
    }
  };
</script>
