<template>
 <div>
    <base-header class="pb-6 content__title content__title--calendar">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
        <div class="col-lg-6 mt-3 mt-lg-0 text-lg-right">
          <base-button class="btn btn-sm btn-primary" type="primary" v-loading="loading" @click="save">{{$t('message.save')}}</base-button>

        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <profile-form :user="myTempUser" :avatar.sync="avatar" v-on:avatarChanged="onAvatarChanged"></profile-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { omit } from 'lodash'
  import ProfileForm from './UserProfile/ProfileForm.vue'

  import UserCard from './UserProfile/UserCard.vue';
  import ProgressTrackList from '@/views/Dashboard/ProgressTrackList';

  export default {
    components: {
      ProfileForm,
      UserCard,
      ProgressTrackList
    },
    computed: {
      ...mapGetters([
        'currentUser',
     ])
    },
    data () {
      return {
        myTempUser: null,
        avatar: null,
        loading: false,
        deleteAvatar: false
      }
    },
    methods: {
      save () {
        const user = omit(this.myTempUser, ['avatar']);
        const p = this.$store.dispatch('updateUser', { id: user.id, data: user });
        this.loading = true

        p
          .then(() => this.uploadFile())
          .then(() => this.$store.dispatch('refreshCurrentUser'))
          .then(() => {
            this.$notify({
              verticalAlign: 'top',
              horizontalAlign: 'right',
              title: this.$t('message.saved'),
              message: this.$t('message.user_saved'),
              type: 'info'
            })
            this.loading = false
          }).catch(e => {
            console.log(e)
            this.$notify({
              verticalAlign: 'top',
              horizontalAlign: 'right',
              title: this.$t('message.error'),
              message: this.$t('message.user_saved_error'),
              type: 'error'
            })
            this.loading = false
          })
      },
      onAvatarChanged (files) {
        this.avatar = files
        if (files === null) {
          this.deleteAvatar = true
        }
      },
      async uploadFile () {
        if (this.avatar) {
          await this.$store.dispatch('uploadDocument', { type:'users', id: this.myTempUser.id, files: this.avatar, api: true })
        } else if (this.deleteAvatar){
          const doc = this.currentUser.avatar ? [this.currentUser.avatar] : []
          if (doc.length > 0) {
            await this.$store.dispatch('deleteDocApi', { docs: doc });
          }
        }
      },
    },
    mounted () {
      this.myTempUser = Object.assign({}, this.currentUser)
    }
  };
</script>
<style>
  .profile-header {
    background-image: url(/img/theme/profile-cover.jpg);
    background-size: cover;
    background-position: center top;
    min-height: 500px;
  }
</style>
