<template>
  <card>
    <div slot="header" class="row align-items-center">
      <div class="col-8">
        <h3 class="mb-0">{{$t('message.profile')}}</h3>
      </div>
      <div class="col-4 text-right">
      </div>
    </div>

    <form v-if="user">
      <h6 class="heading-small text-muted mb-4">{{$t('message.user_information')}}</h6>
      <div class="pl-lg-4">
        <div class="row">
          <div class="col-lg-6">
            <base-input
              type="text"
              :label="$t('message.name')"
              :placeholder="$t('message.name')"
              v-model="user.name" disabled
            >
            </base-input>
          </div>
          <div class="col-lg-6">
            <base-input
              type="email"
              :label="$t('message.email')"
              :placeholder="$t('message.email')"
              v-model="user.email">
            </base-input>
          </div>
          <div class="col-md-6">
              <base-input type="password" :label="$t('message.old_password')" :placeholder="$t('message.password')" v-model="user.old_password" autocomplete="new-password"/>
          </div>
          <div class="col-md-6">
              <base-input type="password" :label="$t('message.new_password')" :placeholder="$t('message.password')" v-model="user.password" autocomplete="new-password"/>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <base-input
              type="text"
              :label="$t('message.position')"
              :placeholder="$t('message.position')"
              v-model="user.position"
            >
            </base-input>
          </div>
          <div class="col-lg-6">
            <!-- <base-input
              type="text"
              label="Nachname"
              placeholder="Last Name"
              v-model="user.lastName"
            >
            </base-input> -->
          </div>
        </div>
      </div>
      <hr class="my-4">

      <!-- Address -->
      <h6 class="heading-small text-muted mb-4">{{$t('message.settings')}}</h6>

      <div class="pl-lg-4">
        <div class="row">
          <div class="col-lg-4">


            <div class="apa-avatar">
              <div class="avatar-upload"  v-show="!avatarEdit">
                <div class="text-left p-2">
                  <label class="avatar-preview" for="avatar">
                    <img :src="avatarFiles.length ? avatarFiles[0].url : user.avatar ? user.avatar.url : ''"  class="avatar-img" />
                  </label>
                </div>
                <div class="text-left p-2 file-upload-container">
                  <file-upload
                    extensions="gif,jpg,jpeg,png,webp"
                    accept="image/png,image/gif,image/jpeg,image/webp"
                    name="avatar"
                    class="btn btn-default btn-sm mb-1"
                    :drop="!avatarEdit"
                    v-model="avatarFiles"
                    @input-filter="inputFilter"
                    @input-file="inputFile"
                    ref="upload">
                    {{$t('message.choose_image')}}
                  </file-upload>
                    <base-button size="sm" type="primary" @click="deleteAvatar" v-if="user.avatar">{{$t('message.delete_avatar')}}</base-button>
                </div>
              </div>

              <div class="avatar-edit" v-show="avatarFiles.length && avatarEdit">
                <div class="avatar-edit-image" v-if="avatarFiles.length">
                  <img ref="editImage" :src="avatarFiles[0].url" />
                </div>
                <div class="text-left p-4">
                  <button type="button" class="btn btn-secondary btn-sm" @click.prevent="clearAvatar">{{$t('message.cancel')}}</button>
                  <button type="submit" class="btn btn-primary btn-sm" @click.prevent="avatarEditSave">{{$t('message.apply')}}</button>
                </div>
              </div>
            </div>

          </div>
          <div class="col-lg-8">
            <base-input :label="$t('message.language')">
                <el-select
                  v-model="user.lang"
                        class="select-primary"
                        :placeholder="$t('message.language')">
                    <el-option v-for="option in locales"
                              class="select-primary"
                              :value="option"
                              :label="$t(`message.lang_${option}`)"
                              :key="option">
                    </el-option>
                  </el-select>
              </base-input>

            <base-input label="API Token" v-if="currentUserRights.includes('profil.apitoken.cansee')">
              <el-input type="textarea" :disabled="true" :value="authToken" />
            </base-input>
              <base-input :label="$t('message.mail_permission')">
                <el-checkbox-group v-model="mailPermissions">
                  <el-checkbox label="REPORT">{{$t('message.mail.daily_report')}}</el-checkbox>
                </el-checkbox-group>
              </base-input>

          </div>

          <div class="col-lg-4">

          </div>
        </div>
      </div>



    </form>
  </card>
</template>
<script>
  import { mapGetters } from 'vuex'
  import FileUpload from 'vue-upload-component'
  import Cropper from 'cropperjs'

export default {
  props: {
    user: Object
  },
  components: {
     FileUpload
  },
  computed: {
    ...mapGetters([
      'locales',
      'authToken',
      'currentUserRights'
    ]),
  },
  data() {
    return {
        avatarFiles: [],
        avatarEdit: false,
        avatarCropper: false,
        mailPermissions: [],
    };
  },
  watch: {
    avatarEdit(value) {
      if (value) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) {
            return
          }
          let avatarCropper = new Cropper(this.$refs.editImage, {
            aspectRatio: 1 / 1,
            viewMode: 1,
          })
          this.avatarCropper = avatarCropper
        })
      } else {
        if (this.avatarCropper) {
          this.avatarCropper.destroy()
          this.avatarCropper = false
        }
      }
    },
    user() {
      this.mailPermissions = this.user.mail_permission
        ? this.user.mail_permission.split(',')
        : []
    },
    mailPermissions() {
      this.user.mail_permission = this.mailPermissions.join(',') || ''
    }
  },
  methods: {

    avatarEditSave() {
        this.avatarEdit = false
        let oldFile = this.avatarFiles[0]
        let binStr = atob(this.avatarCropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1])
        let arr = new Uint8Array(binStr.length)
        for (let i = 0; i < binStr.length; i++) {
          arr[i] = binStr.charCodeAt(i)
        }
        let file = new File([arr], oldFile.name, { type: oldFile.type })

        this.$refs.upload.update(oldFile.id, {
          file,
          type: file.type,
          size: file.size,
          active: true,
        })
        this.user.avatar = {
          url: URL.createObjectURL(file)
        }
        this.files = {
          'avatar': [file]
        }
        this.$emit('avatarChanged', this.files)
      },

      inputFile(newFile, oldFile, prevent) {
        if (newFile && !oldFile) {
          this.$nextTick(function () {
            this.avatarEdit = true
          })
        }
        if (!newFile && oldFile) {
          this.avatarEdit = false
        }
      },

      inputFilter(newFile, oldFile, prevent) {

        if (newFile && !oldFile) {
          if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
            return prevent()
          }
        }
        if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
          newFile.url = ''
          let URL = window.URL || window.webkitURL
          if (URL && URL.createObjectURL) {
            newFile.url = URL.createObjectURL(newFile.file)

          }
        }
      },


    updateProfile() {
      alert('Your data: ' + JSON.stringify(this.user));
    },

    onFileChange (res, files) {
      this.user.avatar = {
        url: URL.createObjectURL(files[0].raw)
      }
    },

    setDocument (data) {
      const avatar = {
        'avatar': [data.file]
      };
      this.$emit('avatarChanged', avatar)
    },

    clearAvatar () {
      this.$refs.upload.clear();
    },

    deleteAvatar () {
      this.user.avatar = null;
      this.$emit('avatarChanged', null)
    }
  }
};
</script>
<style>


.apa-avatar .avatar-edit-image {
  max-height: 300px;
  max-width: 300px !important;
}

.apa-avatar .avatar-upload .rounded-circle {
  width: 200px;
  height: 200px;
}
.apa-avatar .avatar-upload .avatar-preview {
  cursor: pointer;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 200px;
}
.apa-avatar .avatar-upload .avatar-preview:hover {
  opacity: .8;
  border-color: #9f2f37;
}

.apa-avatar .avatar-upload .avatar-preview img {
    width: 100%;
    object-fit: cover;
}

.apa-avatar .text-center .btn {
  margin: 0 .5rem
}
.apa-avatar .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}
.apa-avatar .drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>
